<template>
  <div>
    <b-row>
      <b-col
        v-if="casayesErrorMessage !== ''"
        md="12"
      >
        <b-alert
          show
          variant="danger"
        >
          <div class="alert-body">
            <span class="ml-25">{{ casayesErrorMessage }}</span>
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-if="Number(portals.casayes.enable) !== 1 || portals.casayes.enable === false"
        md="12"
      >
        <p>{{ $t('Não disponível para o teu HUB') }}</p>
      </b-col>
      <b-col
        v-if="Number(portals.casayes.enable) === 1 || portals.casayes.enable === true"
        md="3"
      >
        <b-form-group
          :label="$t('Publicação')"
        >
          <p class="font-weight-bolder mb-0">
            {{ $t('Imediata') }}
          </p>
        </b-form-group>
      </b-col>
      <b-col
        v-if="Number(portals.casayes.enable) === 1 || portals.casayes.enable === true"
        :md="totalColumnsSep"
      >
        <b-form-group
          :label="$t('Publicado?')"
        >
          <b-form-checkbox
            v-if="Number(portals.casayes.allow) === 1 || portals.casayes.allow === true"
            :checked="checkPublishListing"
            :disabled="disablePublishListing"
            class="custom-control-success"
            name="check-button"
            switch
            @change="changeValue"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
          <template v-else>
            <b-form-checkbox
              :checked="checkPublishListing"
              :disabled="true"
              class="custom-control-success"
              name="check-button"
              switch
              inline
            />
            <div class="clearfix" />
            <small>{{ $t('Não aplicável') }}</small>
          </template>
        </b-form-group>
      </b-col>
      <b-col
        v-if="Number(portals.casayes.enable) === 1 || portals.casayes.enable === true"
        :md="totalColumnsSep"
      >
        <b-form-group
          :label="$t('Preço visível?')"
        >
          <b-form-checkbox
            v-if="Number(portals.casayes.allow) === 1 || portals.casayes.allow === true"
            :checked="checkPriceListing"
            :disabled="true"
            class="custom-control-success"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
          <template v-else>
            <b-form-checkbox
              :checked="checkPriceListing"
              :disabled="true"
              class="custom-control-success"
              name="check-button"
              switch
              inline
            />
            <div class="clearfix" />
            <small>{{ $t('Não aplicável') }}</small>
          </template>
        </b-form-group>
      </b-col>
      <b-col
        v-if="showColumnHideSold===true"
        :md="totalColumnsSep"
      >
        <b-form-group
          :label="$t('Ocultar dos vendidos?')"
        >
          <b-form-checkbox
            :checked="false"
            :disabled="true"
            class="custom-control-success"
            name="check-button"
            switch
            inline
          />
          <div class="clearfix" />
          <small>{{ $t('Não aplicável') }}</small>
        </b-form-group>
      </b-col>
      <b-col
        v-if="Number(portals.casayes.enable) === 1 || portals.casayes.enable === true"
        md="3"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          block
          @click="getCriterios('casayes')"
        >
          <span class="align-middle">{{ $t('Critérios') }}</span>
        </b-button>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormCheckbox, BButton, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BAlert,
  },
  directives: {
    Ripple,
  },
  props: {
    formData: {
      type: Object,
      default: () => {
      },
    },
    currentUser: {
      type: Object,
      default: () => {
      },
    },
    urlWebsite: {
      type: String,
      default: () => '',
    },
    modeForm: {
      type: Boolean,
      default: () => false,
    },
    portals: {
      type: Object,
      default: () => {
      },
    },
    getCriterios: {
      type: Function,
      required: true,
    },
    casayesErrorMessage: {
      type: String,
      default: () => '',
    },
  },
  data() {
    let disablePublishListing = false
    let checkPublishListing = false
    let valuePublishListing = 2
    let disablePriceListing = false
    let checkPriceListing = false
    let valuePriceListing = 2

    if ((typeof this.formData.sw012s01 === 'undefined' || this.formData.sw012s01 === null || this.formData.sw012s01 === '')
        || (typeof this.formData.sw012s72 !== 'undefined' && this.formData.sw012s72 !== null && Number(this.formData.sw012s72) === Number(this.currentUser.idHUB) && [5, 9, 10, 13, 6, 11, 12].includes(Number(this.currentUser.id_role)))
        || (typeof this.formData.sw012s72 !== 'undefined' && this.formData.sw012s72 !== null && Number(this.formData.sw012s72) === Number(this.currentUser.idHUB) && typeof this.formData.sw012s04 !== 'undefined' && this.formData.sw012s04 !== null && this.formData.sw012s04 === atob(this.currentUser.id))
        || (typeof this.formData.sw012s72 !== 'undefined' && this.formData.sw012s72 !== null && Number(this.formData.sw012s72) === Number(this.currentUser.idHUB) && typeof this.currentUser.extras.idLeader !== 'undefined' && this.currentUser.extras.idLeader === this.formData.sw012s04 && Object.values(this.currentUser.extras.perm).includes('add_ang_C'))) {
      if (Number(this.portals.casayes.allow === 1) || this.portals.casayes.allow === true) {
        if (typeof this.formData.sw012s546 !== 'undefined' && this.formData.sw012s546 !== null && Number(this.formData.sw012s546) === 1) {
          checkPublishListing = true
          valuePublishListing = 1
        } else {
          disablePriceListing = false
        }

        if (typeof this.formData.sw012s546 !== 'undefined' && this.formData.sw012s546 !== null && Number(this.formData.sw012s546) === 1) {
          checkPriceListing = true
          valuePriceListing = 1
        }

        if (((typeof this.formData.sw012s01 !== 'undefined' && this.formData.sw012s01 !== null)
            && (typeof this.formData.sw012s64 !== 'undefined' && this.formData.sw012s64 !== null && Number(this.formData.sw012s64.sw018s01) === 5)
            && (typeof this.formData.sw012s70 !== 'undefined' && this.formData.sw012s70 !== null && Number(this.formData.sw012s70) === 1)
            && (typeof this.formData.sw012s261 === 'undefined' || this.formData.sw012s261 === null)) || (typeof this.formData.sw012s01 === 'undefined' && this.formData.sw012s01 === null)) {
          checkPublishListing = true
          checkPriceListing = true
          valuePublishListing = 1
          valuePriceListing = 1
          disablePriceListing = false
        } else if ((typeof this.formData.sw012s01 !== 'undefined' && this.formData.sw012s01 !== null)
            && (typeof this.formData.sw012s64 !== 'undefined' && this.formData.sw012s64 !== null && Number(this.formData.sw012s64.sw018s01) === 5)
            && (typeof this.formData.sw012s70 === 'undefined' || this.formData.sw012s70 === null || Number(this.formData.sw012s70) === 0)
            && (typeof this.formData.sw012s261 === 'undefined' || this.formData.sw012s261 === null)) {
          // NAO APLICA NADA
        } else if ((typeof this.formData.sw012s01 !== 'undefined' && this.formData.sw012s01 !== null)
            && (typeof this.formData.sw012s64 !== 'undefined' && this.formData.sw012s64 !== null && Number(this.formData.sw012s64.sw018s01) === 1)
            && (typeof this.formData.sw012s261 === 'undefined' || this.formData.sw012s261 === null)) {
          // NAO APLICA NADA
        } else if (((typeof this.formData.sw012s01 !== 'undefined' && this.formData.sw012s01 !== null) && (typeof this.formData.sw012s64.sw018s01 !== 'undefined' && this.formData.sw012s64.sw018s01 !== null && Number(this.formData.sw012s64.sw018s01) !== 1))
            || (typeof this.formData.sw012s261 !== 'undefined' && this.formData.sw012s261 !== null)) {
          disablePublishListing = true
          disablePriceListing = true
          checkPublishListing = false
          checkPriceListing = false
          valuePublishListing = 2
          valuePriceListing = 2
        }

        if ((typeof this.formData.sw012s01 !== 'undefined' && this.formData.sw012s01 !== null)
            && (typeof this.formData.sw012s64 !== 'undefined' && this.formData.sw012s64 !== null && Number(this.formData.sw012s64.sw018s01) === 5)
            && (typeof this.formData.sw012s64_last !== 'undefined' && this.formData.sw012s64_last !== null && Number(this.formData.sw012s64_last) !== 1)
            && (this.modeForm === false)
            && (typeof this.formData.sw012s373 === 'undefined' || this.formData.sw012s373 === null)) {
          disablePublishListing = true
          disablePriceListing = true
        }
      } else {
        disablePublishListing = true
        checkPublishListing = false
        valuePublishListing = 3

        disablePriceListing = true
        checkPriceListing = false
        valuePriceListing = 3
      }
    } else if (Number(this.portals.casayes.allow === 1) || this.portals.casayes.allow === true) {
      if (typeof this.formData.sw012s546 !== 'undefined' && this.formData.sw012s546 !== null && Number(this.formData.sw012s546) === 1) {
        disablePublishListing = true
        checkPublishListing = true
        valuePublishListing = 1
      } else {
        disablePublishListing = true
        checkPublishListing = false
        valuePublishListing = 2
      }

      if (typeof this.formData.sw012s546 !== 'undefined' && this.formData.sw012s546 !== null && Number(this.formData.sw012s546) === 1) {
        disablePriceListing = true
        checkPriceListing = true
        valuePriceListing = 1
      } else {
        disablePriceListing = true
        checkPriceListing = false
        valuePriceListing = 2
      }
    } else {
      disablePublishListing = true
      checkPublishListing = false
      valuePublishListing = 3

      disablePriceListing = true
      checkPriceListing = false
      valuePriceListing = 3
    }

    this.formData.sw012s546 = valuePublishListing
    this.formData.sw012s548 = valuePriceListing

    return {
      disablePublishListing,
      checkPublishListing,
      valuePublishListing,
      disablePriceListing,
      checkPriceListing,
      valuePriceListing,
    }
  },
  computed: {
    totalColumnsSep() {
      return ((this.showColumnHideSold === true) ? 2 : 3)
    },
    showColumnHideSold() {
      const showOption = (Number(this.portals.casayes.enable) === 1 || this.portals.casayes.enable === true)

      return (!!(((this.$store.getters['auth/countryApp'] === 'pt') && (typeof this.formData.sw012s64.sw018s01 !== 'undefined' && this.formData.sw012s64.sw018s01 !== null && Number(this.formData.sw012s64.sw018s01) === 3) && (showOption === true))))
    },
  },
  methods: {
    async changeValue(event) {
      if (event !== false) {
        this.checkPriceListing = true
        this.valuePriceListing = 1
        this.formData.sw012s546 = 1
        this.formData.sw012s548 = 1
      } else {
        this.checkPriceListing = false
        this.valuePriceListing = 2
        this.formData.sw012s546 = 2
        this.formData.sw012s548 = 2
      }
    },
  },
  setup() {
    return {}
  },
}
</script>
